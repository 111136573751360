<template>
    <div>
        <div class="declare_tutor clearfix">
            <div class="form_div">
                <div class="Addme" id="Addme">
                    <el-button size="mini" type="primary" @click="vieSch()">添加教师</el-button>
                </div>
            </div>
            <div class="form_div">
                <el-table 
                    :data="teachList" 
                    style="width: 100%;min-height:200px"
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
                 >
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="age" label="年龄"></el-table-column>
                    <el-table-column prop="phone" label="联系电话"></el-table-column>
                    <el-table-column prop="email" label="邮箱地址"></el-table-column>
                    <el-table-column prop="researchOrientation" label="研究方向"></el-table-column>
                    <el-table-column prop="executivePosition" label="行政职务/专业技术职务"></el-table-column>
                    <el-table-column prop="outcome" label="主要成果"></el-table-column>
                    <el-table-column  label="操作" width="150px">
                        <template slot-scope="scope">
                            <el-button @click="deleteteacher(scope.row)" size="mini" type="danger">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="next_step">
                <el-button type="primary" @click="previous()">上一步</el-button>
                <el-button type="primary" @click="complete()">完 成</el-button>
            </div>
        </div>
        <div class="add_Popup">
            <el-dialog
                title="添加指导教师"
                :visible.sync="dialogVisibleForm"
                width="500px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('Form')"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="tId" label="教师姓名" style="width:100%">
                        <el-select v-model="Form.tId" placeholder="请选择姓名" @change="Chteac(Form.tId)">
                            <el-option 
                            v-for="(item,index) in departList"
                            :key="index"
                            :label="item.name" 
                            :value="item.tId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="age" label="教师年龄" style="width:100%">
                        <el-input v-model="Form.age" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="phone" label="联系电话" style="width:100%">
                        <el-input v-model="Form.phone" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="email" label="邮箱地址" style="width:100%">
                        <el-input v-model="Form.email" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="executivePosition" label="行政职务/专业技术职务" style="width:100%;" class="form_lable">
                        <el-input v-model="Form.executivePosition" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="researchOrientation" label="研究方向" style="width:100%">
                        <el-input v-model="Form.researchOrientation" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="outcome" label="主要成果" style="width:100%">
                        <el-input type="textarea" :rows="5" v-model="Form.outcome" disabled></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers('Form')">确 定</el-button>
                    <el-button size="small" @click="closei('Form')">取 消</el-button>
                </span>
            </el-dialog>
        </div>  
    </div>
</template>
<script>
export default {
    data () {
        return {
            dialogVisibleForm:false,
            teachList:[],
            departList:[],
            Form:{
                researchOrientation:'',
                executivePosition:'',
                age:'',
                tId:'',
                outcome:'',
                email:'',
            },
            rules:{
                tId:[
                    { required: true, message: '请选择教师', trigger: 'change' }
                ],
            },
        }
    },
    methods: {
        getapp(){
            if(this.$store.state.itemId!=null || this.$store.state.itemId!=''){
                this.axios.getInfo({
                    params:{
                        uid:this.$store.state.uid,
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        this.teachList = res.data.data.teachers
                    }else{
                        this.teachList = []
                    }
                }).catch(err=>{

                })
            }else{
                return false
            }
        },
        //查询教师
        vieSch(){
            this.axios.listDcTeacher({
                params:{
                    schoolId:this.$store.state.schId,
                    itemId:this.$store.state.itemId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.departList = res.data.rows
                    this.dialogVisibleForm = true
                }else{
                    this.departList = []
                }
            }).catch(err=>{

            })
        },
        // 选择教师
        Chteac(val){
            this.departList.map(item=>{
                if(item.tId == val){
                    this.Form.age = item.age
                    this.Form.phone = item.phone
                    this.Form.email = item.email
                    this.Form.outcome = item.outcome
                    this.Form.researchOrientation = item.researchOrientation
                    this.Form.executivePosition = item.executivePosition
                }
            })
        },
        //添加教师
        addmbers(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Form.itemId = this.$store.state.itemId
                    this.axios.addDcItemTeacher(
                        this.Form
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisibleForm = false
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //删除教师
        deleteteacher(rows){
            this.axios.deleteTeacher({
                params:{
                    itemTid:rows.itemTid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.getapp()
                }
            }).catch(err=>{

            })
        },
        // 上一步
        previous(){
            this.$router.push('/Newmembers')
        },
        // 下一步
        complete(){
            this.$router.push('/Projectview')
        },
        handleClose(done){
            console.log(done)
        },
        closei(formName){
            this.dialogVisibleForm =false
            this.$refs[formName].resetFields();
        },
    },
    mounted () {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Projectapplication.css';
</style>
<style>
.add_Popup .el-dialog__header{
    background: #409eff;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
}
.add_Popup .el-dialog__title{
    color: #fff;
}
.add_Popup .el-input__inner{
    width: 300px;
}
.add_Popup .el-dialog__footer{
    text-align: center;
}
.add_Popup .el-textarea__inner{
    resize: none;
}
.add_Popup .form_lable .el-form-item__label{
    line-height: 20px;
}
</style>